<!--
 * @Author: ZerroRt
 * @lastEditors: ZerroRt
 * @Date: 2021-01-20 14:07:14
 * @LastEditTime: 2021-02-05 17:59:58
 * @FilePath: \acuconference-web\src\components\configration\emailConfigration.vue
-->
<template>
  <v-card :loading="loading" :disabled="loading">
    <v-card-text>
      <v-form v-model="valid">
        <v-container>
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                :label="$t('label_input_sender_name') + ' *'"
                v-model="emailInfo.subjectName"
                maxLength="500"
                :rules="required"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6">
              <v-combobox
                multiple
                v-model="emailInfo.ccEmails"
                :label="$t('label_input_email_cc')"
                chips
                deletable-chips
                autocomplete="off"
                class="tag-input"
                :append-icon="''"
                ref="emailCC"
              >
              </v-combobox>
            </v-col> </v-row
          ><v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                :label="$t('label_input_smtp_sending_email_account') + ' *'"
                v-model="emailInfo.sourceEmail"
                :rules="required"
                maxLength="500"
              ></v-text-field>
            </v-col> </v-row
          ><v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                :label="$t('label_normal_password') + ' *'"
                type="password"
                v-model="emailPassword"
                autocomplete="new-password"
                :rules="required"
                maxLength="500"
              ></v-text-field>
            </v-col> </v-row
          ><v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                :label="$t('label_smtp_host_name') + ' *'"
                v-model="emailInfo.smtpHostName"
                :rules="required"
                maxLength="500"
              ></v-text-field>
            </v-col> </v-row
          ><v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                :label="$t('label_smtp_port') + ' *'"
                v-model="emailInfo.smtpPort"
                type="number"
                maxLength="500"
                :rules="required"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
    <v-card-actions class="justify-center">
      <v-btn color="primary" @click="save">
        {{ $t("label_btn_save") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import apis from "../../mixins/apis";
export default {
  mixins: [apis],
  data() {
    return {
      required: [(v) => !!v || this.$t("error_tips_required_params")],
      newEmailCC: "",
      valid: false,
      unChange: true,
      emailPassword: "Now you see me",
      emailInfo: {
        subjectName: "",
        ccEmails: "",
        sourceEmail: "",
        email: "",
        smtpHostName: "",
        smtpPort: "",
        id: "",
      },
      startWatch: false,
      canSavePassword: false
    };
  },
  created() {
    this.initEmailInfo();
  },
  watch: {
    emailInfo: {
      deep: true,
      handler(val) {
        console.error(val)
        if (this.startWatch) {
          this.unChange = false;
        }
      },
    },
    emailPassword() {
      this.canSavePassword = true;
    }
  },
  methods: {
    updateEmailCCs() {
      this.$nextTick(() => {
        process.env.NODE_ENV === 'development' && console.log(this.newEmailCC);
        this.emailInfo.ccEmails.push(...this.newEmailCC.split(","));
        this.$nextTick(() => {
          this.newEmailCC = "";
        });
      });
    },
    async initEmailInfo() {
      const result = await this.getEmailInfo();
      if (result) {
        this.emailInfo = result;
        this.$nextTick(() => (this.startWatch = true));
      }
    },
    save() {
      this.$refs.emailCC.blur();
      this.$nextTick(async () => {
        if (this.canSavePassword) {
          this.emailInfo.password = this.emailPassword
        }
        const result = await this.saveEmailInfo(this.emailInfo);
        if (result) {
          this.$toast.success(this.$t("prompt_success"));
        }
      })
    },
  },
};
</script>

<style lang="sass" scoped>
</style>