<!--
 * @Author: ZerroRt
 * @lastEditors: ZerroRt
 * @Date: 2021-01-20 11:56:11
 * @LastEditTime: 2021-02-07 12:33:01
 * @FilePath: \acuconference-web\src\components\configration\operatorList.vue
-->
<template>
  <v-card style="width: 100%">
    <v-card-title>
      <v-btn
        :disabled="!selectColumn.length"
        color="error"
        fab
        small
        :title="$t('label_btn_delete')"
        @click="deleteSelectUsers"
      >
        <v-icon>mdi-delete</v-icon>
      </v-btn>
      <v-btn
        color="primary"
        style="margin-left: 20px"
        fab
        small
        :title="$t('label_btn_add_operator')"
        @click="editUser"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        :label="$t('label_search')"
        single-line
        maxLength="500"
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-card-text>
      <v-data-table
        :disable-sort="true"
        :locale="tableLocal"
        style="width: 100%"
        :items-per-page="limit"
        :loading="loading"
        :headers="headers"
        :items="list"
        :server-items-length="totalCount"
        show-select
        :page="page"
        @update:page="pageUpdate"
        @update:items-per-page="limitUpdate"
        :footer-props="tableFooterOptions"
        v-model="selectColumn"
      >
        <template v-slot:no-data>
          <span>
            {{$t('table_no_data')}}
          </span>
        </template>
        <template v-slot:item.account="{ item }">
          <v-btn text color="primary" @click="editUser(item)">
            {{ item.account }}
          </v-btn>
        </template>
        <template v-slot:item.enableAccount="{ item }">
          <v-simple-checkbox
            disabled
            :ripple="false"
            :value="item.enableAccount"
          ></v-simple-checkbox>
        </template>
      </v-data-table>
    </v-card-text>
    <Profile
      v-model="showEditProfileDialog"
      :forceType="operatorType"
      :activeUser="activeUser"
      @finish="initUserList"
      @refreshSelfData="refreshCurrentUserData"
    />
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import apis from "../../mixins/apis";
import Profile from "../dialogs/profile";
export default {
  mixins: [apis],
  components: { Profile },
  data() {
    return {
      operatorType: 200,
      search: "",
      searchTimer: 0,
      list: [],
      totalCount: 0,
      limit: 10,
      page: 1,
      showEditProfileDialog: false,
      selectColumn: [],
      activeUser: {},
    };
  },
  watch: {
    page: {
      immediate: true,
      handler() {
        this.initUserList();
      },
    },
    limit() {
      this.initUserList();
    },
    activeUser(val) {
      if (!!val !== this.showEditProfileDialog) {
        this.showEditProfileDialog = !!val;
      }
    },
    showEditProfileDialog(val) {
      if (!val) {
        this.activeUser = null;
      }
    },
    search() {
      clearTimeout(this.searchTimer);
      this.searchTimer = setTimeout(() => {
        if (this.page !== 1) {
          this.page = 1;
        } else {
          this.initUserList();
        }
      }, 200);
    },
  },
  created() {},
  computed: {
    getPage() {
      return Math.max(this.page + 1, 0);
    },
    headers() {
      return [
        {
          text: this.$t("label_normal_account"),
          value: "account",
          align: "center",
        },
        {
          text: this.$t("label_user_name"),
          value: "name",
          align: "center",
        },
        {
          text: this.$t("label_user_email"),
          value: "email",
          align: "center",
        },
        {
          text: this.$t("label_input_label_enable_account"),
          value: "enableAccount",
          align: "center",
        },
      ];
    },
  },
  methods: {
    ...mapActions("userInfo", ["refreshCurrentUserData"]),
    async initUserList() {
      let offset = (this.page - 1) * this.limit,
        limit = this.limit;
      const params = {
        o: offset,
        l: limit,
        t: this.operatorType,
      };
      if (this.search) {
        params.k = encodeURIComponent(this.search);
      }
      const result = await this.getListsFromServer(
        process.env.VUE_APP_API_GET_USERS,
        params
      );
      if (result) {
        const { users, total } = result;
        this.list = users;
        this.totalCount = total;
      }
    },
    addOperator() {},
    pageUpdate(page) {
      this.page = page;
    },
    limitUpdate(limit) {
      this.limit = limit;
    },
    editUser(user = {}) {
      if (user.id) {
        this.activeUser = user;
      } else {
        const operatorType = 200
        this.activeUser = {
          account: "",
          email: "",
          type: operatorType,
          address: "",
          name: "",
          enableAccount: true,
          phone: "",
          mobile: "",
          password: "",
        };
      }
    },
    async deleteSelectUsers() {
      const ids = this.selectColumn.map((column) => {
        return column.id;
      });
      const confirmDelete = await this.$confirm.warn(
        this.$t("label_delete_confirm"),
        {
          title: this.$t("label_warn"),
          buttons: [
            {
              label: this.$t("label_btn_cancel"),
              value: "cancel",
              color: "primary",
            },
            {
              label: this.$t("label_btn_confirm"),
              value: "confirm",
              color: "primary",
            },
          ],
        }
      );
      process.env.NODE_ENV === "development" && console.log(confirmDelete);
      if (confirmDelete === "confirm") {
        const result = await this.deleteUser({
          ids: ids,
        });
        if (result) {
          // set page to 0 to refresh this page
          this.$toast.success(this.$t("prompt_success"));
          this.$nextTick(() => {
            this.initUserList();
          });
          this.selectColumn = [];
          return;
        }
        this.selectColumn = [];
        this.initUserList();
      }
    },
  },
};
</script>

<style lang="sass" scoped>
</style>