<!--
 * @Author: ZerroRt
 * @lastEditors: ZerroRt
 * @Date: 2021-01-14 18:10:26
 * @LastEditTime: 2021-01-27 19:02:51
 * @FilePath: \acuconference-web\src\views\admin\configuration.vue
-->
<template>
  <div class="configuration-wrapper">
    <v-app-bar dense class="elevation-0 v-bar--underline">
      <v-tabs
        v-model="activeTab"
        :class="{
          'config-loading': loading,
        }"
      >
        <v-tab
          v-for="tab in renderTabs"
          :key="tab.id"
          >{{ $t(tab.label) }}</v-tab
        >
      </v-tabs>
    </v-app-bar>
    <OperatorList v-if="activeTabId === 'operator'" />
    <EmailConfigration v-else-if="activeTabId === 'emailConfigration'" />
  </div>
</template>

<script>
import OperatorList from "../../components/configration/operatorList"
import EmailConfigration from "../../components/configration/emailConfigration"
export default {
  components: { OperatorList, EmailConfigration },
  data() {
    return {
      activeTab: 0,
      activeTabId: '',
      loading: false,
    };
  },
  computed: {
    nativeTabs() {
      const tabs = [
        {
          id: "operator",
          label: "label_tab_Operator",
        },
        {
          id: "emailConfigration",
          label: "label_user_email",
        },
      ];

      return tabs;
    },
    renderTabs() {
      const tabs = this.nativeTabs;
      return tabs;
    },
  },

  watch: {
    activeTab: {
      immediate: true,
      handler(val) {
        this.doActiveTabAction(this.renderTabs[val].id)
      }
    }
  },
  methods: {
    doActiveTabAction(type) {
      this.activeTabId = type
      switch (type) {
        case 'operator':
          break;
      
        default:
          break;
      }
    }
  },
};
</script>

<style lang="sass" scoped>
.configuration-wrapper
  position: relative
  width: 100%
  height: 100%
  .config-loading
    filter: grayscale(1)
    pointer-events: none
</style>